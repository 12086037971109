import React from "react"
import Banner from "../content/ExperienceBM/Banner"
import ContentBM from "../content/ExperienceBM/ContentBM"
import Mobile from "../content/Mobile"
import Footer from "../layout/Footer"
import "./cssberhasil"
import Header from "./HeaderSub"
import Navbar from "./NavbarSub"
//import Table from '../content/ExperienceBM/Table/Main';
import Helmet from "react-helmet"
import Loadable from "react-loadable"
import PhotoServices from "../content/ExperienceBA/ServicesCont"
import SEO from "../SEO"
import JSberhasil from "./jsberhasil"

// import TitleServices from '../content/ExperienceBA/TitleContent';
// import PhotoServices from '../content/ExperienceBA/ServicesCont';

import ContactBus from "../content/ContactBus"

import DiagramBM from "../content/ExperienceBM/DiagramBM"

function Loading(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else if (props.pastDelay) {
    return <div>Loading...</div>
  } else {
    return null
  }
}

let LoadableTable = Loadable({
  loader: () => import("../content/ExperienceBM/Table/Main"),
  loading: Loading,
})

const LayoutExperienceBM = () => {
  return (
    // <>
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074",
        }}
      />
      <SEO
        title="Pharmetrics Lab | Contract Research Analysis Experiences "
        description="Pharmametrics Lab as the leading CRO company in Indonesia have experiences in handling various research analysis samples such as Vitamin and Antibiotics"
      />
      {/*
			<div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
			</div>
			*/}

      <div id="perspective">
        <Mobile />
        <div
          id="wrapper"
          className="hasbg transparent"
          style={{ paddingTop: "0px" }}
        >
          <div id="elementor-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Header />
                  <Navbar />
                </div>
              </div>
            </div>
          </div>
          <div id="elementor-sticky-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Navbar />
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" className="">
            <div className="inner">
              <div className="inner-wrapper">
                <div className="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    className="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <Banner />
                        <ContentBM />
                        <DiagramBM />
                        <PhotoServices />
                        <ContactBus />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
      {/* </> */}
    </React.Fragment>
  )
}

export default LayoutExperienceBM
